import posthog from "posthog-js";
import { HiSparkles } from "react-icons/hi2";
import { Link } from "@nextui-org/link";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoYoutube,
} from "react-icons/io5";
import { useTheme } from "next-themes";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  MdLightMode,
  MdOutlineDarkMode,
  MdOutlineDesktopWindows,
} from "react-icons/md";

export default function Footer() {
  const { theme, setTheme } = useTheme();

  return (
    <div className="flex flex-col px-4 sm:px-6 py-8 gap-6 bg-default-50">
      <div className="flex flex-col sm:flex-row justify-between gap-8">
        <div className="">
          <span className="block text-default-500 font-black text-3xl">
            tribe<span className="text-base">.best</span>
          </span>
          <p className="text-default-500">
            Find service experts who get your vibe
            <HiSparkles className="inline-block ml-1 mb-0.5" />
          </p>
          <div className="flex gap-2 mt-4">
            <Link
              isExternal
              onPress={() => {
                posthog.capture("footer_social_clicked", {
                  type: "instagram",
                });
              }}
              href="https://www.instagram.com/tribe.best"
            >
              <IoLogoInstagram className="size-5" />
            </Link>
            <Link
              isExternal
              onPress={() => {
                posthog.capture("footer_social_clicked", {
                  type: "linkedin",
                });
              }}
              href="https://in.linkedin.com/company/tribe-best"
            >
              <IoLogoLinkedin className="size-5" />
            </Link>
            <Link
              isExternal
              onPress={() => {
                posthog.capture("footer_social_clicked", {
                  type: "facebook",
                });
              }}
              href="https://www.facebook.com/people/tribebest/61555699682937/"
            >
              <IoLogoFacebook className="size-5" />
            </Link>
            <Link
              isExternal
              onPress={() => {
                posthog.capture("footer_social_clicked", {
                  type: "youtube",
                });
              }}
              href="https://www.youtube.com/@tribe.besttt"
            >
              <IoLogoYoutube className="size-5" />
            </Link>
            <Dropdown>
              <DropdownTrigger>
                <Button className="-ml-2" variant="light" isIconOnly>
                  {theme === "light" ? (
                    <MdLightMode className="size-5 text-primary" />
                  ) : theme === "dark" ? (
                    <MdOutlineDarkMode className="size-5 text-primary " />
                  ) : (
                    <MdOutlineDesktopWindows className="size-5 text-primary" />
                  )}
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  key="light"
                  startContent={<MdLightMode />}
                  onPress={() => setTheme("light")}
                >
                  Light
                </DropdownItem>
                <DropdownItem
                  key="dark"
                  startContent={<MdOutlineDarkMode />}
                  onPress={() => setTheme("dark")}
                >
                  Dark
                </DropdownItem>
                <DropdownItem
                  key="system"
                  startContent={<MdOutlineDesktopWindows />}
                  onPress={() => setTheme("system")}
                >
                  System
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div></div>
        </div>

        <div className="flex flex-col sm:flex-row gap-6 sm:gap-8 sm:mr-8">
          <div>
            <h5 className="mb-2 font-medium text-default-600">Cities</h5>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/c/bengaluru"
              >
                Bengaluru
              </Link>
            </div>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/c/bengaluru"
                isDisabled
              >
                Delhi
              </Link>
            </div>
          </div>
          <div>
            <h5 className="mb-2 font-medium text-default-600">Company</h5>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/about"
              >
                About
              </Link>
            </div>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                isExternal
                showAnchorIcon
                underline="hover"
                href="https://in.linkedin.com/company/tribe-best/jobs"
              >
                Careers
              </Link>
            </div>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/become-expert"
              >
                Become an Expert
              </Link>
            </div>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/contact"
              >
                Contact
              </Link>
            </div>
          </div>
          <div>
            <h5 className="mb-2 font-medium text-default-600">Legal</h5>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/terms"
              >
                Terms & Conditions
              </Link>
            </div>
            <div>
              <Link
                className="text-default-500"
                size="sm"
                underline="hover"
                href="/privacy"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <p className="block text-xs text-default-500">
          © Speakleap Technologies Private Limited
        </p>
      </div>
    </div>
  );
}
