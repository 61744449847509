import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect, useCallback } from "react";
import { MdOutlineWrongLocation } from "react-icons/md";
import { useUser } from "~/stores/user";
import {
  BANGALORE_BOUNDING_BOX,
  isWithinBoundingBox,
  NCR_BOUNDING_BOX,
} from "~/utils/location";
import posthog from "posthog-js";
import { BANGALORE } from "~/utils/constants";

export default function LocationWarningModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { location, setLocation } = useUser();

  const handleClose = useCallback(() => {
    posthog.capture("location_warning_modal_closed");
    setLocation({
      lat: BANGALORE.latitude,
      lng: BANGALORE.longitude,
      label: BANGALORE.label,
    });
    onClose();
  }, [onClose, setLocation]);

  useEffect(() => {
    if (location) {
      const isNCR = isWithinBoundingBox(location, NCR_BOUNDING_BOX);
      const isBangalore = isWithinBoundingBox(location, BANGALORE_BOUNDING_BOX);
      if (!isNCR && !isBangalore) {
        onOpen();
      }
    }
  }, [location, onOpen]);

  useEffect(() => {
    if (isOpen) {
      posthog.capture("location_warning_modal_opened");
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={handleClose}>
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody className="flex flex-col items-center gap-6">
            <MdOutlineWrongLocation className="w-16 h-16 text-primary" />
            <p className="text-center">
              Our amazing experts are in Bangalore and Delhi NCR right now. Your
              city is next - but give us a spin! With a button &quot;Explore
              Tribe&quot; just below
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onPress={handleClose}>
              Explore Tribe
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
